import * as React from 'react';
import { Link } from 'gatsby';
import Seo from '../../components/seo';
import Layout from '../../components/layout';
import TestResult from "../../components/test-result";


const params = [
  '__hsfp', '__hssc', '__hstc', '__s', '_hsenc', '_openstat', 'dclid',
  'fb_action_ids', 'fb_action_types', 'fb_ref', 'fb_source', 'fbclid',
  'gbraid', 'gclid', 'hsCtaTracking', 'igshid', 'mc_eid',
  'ml_subscriber', 'ml_subscriber_hash', 'msclkid',
  'oft_c', 'oft_ck', 'oft_d', 'oft_id', 'oft_ids', 'oft_k', 'oft_lk', 'oft_sk',
  'oly_anon_id', 'oly_enc_id', 'rb_clickid', 's_cid', 'twclid',
  'utm_campaign', 'utm_channel', 'utm_cid', 'utm_content', 'utm_medium', 'utm_name', 'utm_place',
  'utm_pubreferrer', 'utm_reader', 'utm_referrer', 'utm_social', 'utm_social-type', 'utm_source',
  'utm_swu', 'utm_term', 'utm_userid', 'utm_viz_id',
  'vero_conv', 'vero_id', 'wbraid', 'wickedid', 'yclid',
  'mkt_tok',
];

const UTMPage = ({ location }) => {
  const queryParams = new URLSearchParams(location.search);

  let runTest = () => {
    const url = new URL(window.location.href);
    const search = params.map((k, i) => `${k}=${i}`).join('&');
    url.search = new URLSearchParams(search + "&started=true").toString();
    window.location.replace(url.toString());
  };
  let started = queryParams.has("started");
  if (queryParams.has("autoStart") && !started) {
    runTest();
  }
  return (
    <Layout>
      <h1>Remove Marketing Tracking parameters</h1>
      <p>Remove marketing tracking parameters (e.g. utm_source, utm_campaign and others). Keep websites from tagging you
        as you surf the web.</p>

      <button
        id="TestAction"
        className="bg-emerald-600 text-white w-full mb-10 mt-5"
        onClick={runTest}
      >
        Run test
      </button>

      <table className="table-fixed border-collapse border-2 text-left w-full mb-10">
        <thead className="bg-slate-200">
        <tr>
          <th className="border border-slate-300 font-semibold p-4">Property</th>
          <th className="border border-slate-300 font-semibold p-4">Value</th>
          <th className="border border-slate-300 text-center font-semibold p-4">Test result</th>
        </tr>
        </thead>
        <tbody>
        {
          params.map((paramName, index) => (
            <tr className="border-2" key={index}>
              <td className="border p-2">
                <code>{paramName}</code>
              </td>
              <td className="border p-2">
                {
                  queryParams.has(paramName) ?
                    <code>{queryParams.get(paramName)}</code> :
                    <small><i>(empty)</i></small>
                }

              </td>
              <TestResult result={started ? !queryParams.has(paramName) : undefined} colorStyle="bg">
                <td
                  className="border p-2 text-white text-center">
                  {started ? queryParams.has(paramName) ? 'Failed' : 'Passed' : 'Unknown'}
                </td>
              </TestResult>
            </tr>
          ))
        }
        </tbody>
      </table>

      <Link to="/">
        Go back
      </Link>
    </Layout>
  );
}

export const Head = () => <Seo title="Remove Marketing Tracking parameters"/>
export default UTMPage;
